import { Box, Button, Divider, Flex, Group, Image, Input, Stack, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Virtualizer } from '@tanstack/react-virtual';
import {
  MRT_ColumnDef,
  MRT_TableInstance,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';

import { useAppStore } from 'stores/appStore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDown } from '@fortawesome/sharp-regular-svg-icons';
import { useNavigate } from '@tanstack/react-router';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { CalendarEvent, CalendarEventIndex, CalendarMatchupFull } from 'utils/scheduleConsts';
import { getFullSchedule, parseMatchup } from 'utils/scheduleUtils';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export const downloadCSV = (entries: CalendarEvent[]) => {
  let csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += 'Date,Game,Time (ET),Network,Viewership (000)\n';
  const csvLines = entries.map((e) => {
    const viewership = e.viewership || 0;
    let viewershipStr = `${viewership}`;
    if (viewership >= 1000) {
      viewershipStr = `${(viewership / 1000).toFixed(0)}`;
    }
    const dateStr = dayjs(e.date).format('ddd MMM DD');
    return `${dateStr},${e.title},${e.eastern_time},${e.network ? `"${e.network}"` : ''},${viewershipStr}`;
  });
  const csv = csvContent + csvLines.join('\n');
  const encodedUri = encodeURI(csv);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'Recentive Analytics - NHL Schedule.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function DateSummary() {
  const navigate = useNavigate({ from: '/schedules' });
  const currentScheduleId = useAppStore((state) => state.currentScheduleId);
  const [fullSchedule, setFullSchedule] = useState<CalendarMatchupFull[]>([]);
  const [eventDates, setEventDates] = useState<string[]>([]);
  const rowVirtualizerInstanceRef = useRef<Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);
  const [selectedDate, setSelectedDate] = useState<string>('');

  let constraintsTable: MRT_TableInstance<CalendarEvent> | null = null;

  const [events, setEvents] = useState<CalendarEventIndex>([]);

  useEffect(() => {
    navigate({
      to: '/schedules',
      search: (prev) => ({
        s: prev.s,
        t: 'date',
        team: prev.team,
        compare: prev.compare,
      }),
    });

    async function fetchScheduleJson() {
      const schedule = await getFullSchedule(currentScheduleId);
      setFullSchedule(schedule);

      const newDates = Array.from(new Set(schedule.map((matchup) => matchup.date)));
      setEventDates(newDates);
      if (selectedDate && newDates.includes(selectedDate)) {
        setSelectedDate(selectedDate);
      } else {
        setSelectedDate(newDates[0]);
      }
    }
    fetchScheduleJson();
  }, [currentScheduleId]);

  useEffect(() => {
    navigate({
      to: '/schedules',
      search: (prev) => ({
        s: prev.s,
        t: 'date',
        team: prev.team,
        compare: prev.compare,
      }),
    });

    if (selectedDate) {
      const newEvents: CalendarEvent[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of fullSchedule) {
        newEvents.push({
          date: entry.date,
          eastern_time: entry.eastern_time,
          title: `${entry.away} @ ${entry.home}`,
          network: entry.network,
          viewership: entry.viewership,
          blackouts: entry.blackouts,
        });
      }

      setEvents(newEvents);

      // Scroll to the target date when changing schedules or selected a date.
      // Wrapped ina  setTimeout so that the entries update.
      setTimeout(() => {
        if (rowVirtualizerInstanceRef.current) {
          try {
            if (constraintsTable) {
              const { rows } = constraintsTable.getRowModel();
              // @ts-ignore
              const targetRow = rows.find((row) => row.original.date === selectedDate);
              if (targetRow) {
                rowVirtualizerInstanceRef.current.scrollToIndex(rows.indexOf(targetRow), {
                  align: 'start',
                  behavior: 'auto',
                });
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      }, 10);
    } else {
      setEvents([]);
    }
  }, [selectedDate, fullSchedule]);

  const columns = useMemo<MRT_ColumnDef<CalendarEvent>[]>(
    () => [
      {
        accessorKey: 'date',
        header: 'Date',
        GroupedCell: ({ cell, row }) => `${cell.getValue()} (${row.subRows?.length})`,
        accessorFn: (row) => dayjs(row.date).format('ddd, MMM DD'),
        sortingFn: (rowA, rowB) => {
          const a = dayjs(rowA.original.date).format('YYYY-MM-DD');
          const b = dayjs(rowB.original.date).format('YYYY-MM-DD');
          return a.localeCompare(b);
        },
        filterFn: 'contains',
      },
      {
        id: 'game',
        accessorFn: (row) => {
          const { displayValue } = parseMatchup(row);
          return displayValue;
        },
        Cell: ({ renderedCellValue, row }) => {
          const { home, away } = parseMatchup(row.original);
          return (
            <Group gap="5px" align="center">
              <Box h={20}>
                <Image
                  src={new URL(`/src/assets/team_logos/${away}_logo.png`, import.meta.url).href}
                  height={20}
                  fit="cover"
                  alt={`${away} logo`}
                />
              </Box>
              <Text size="sm" component="span">
                {renderedCellValue}
              </Text>
              <Box h={20}>
                <Image
                  src={new URL(`/src/assets/team_logos/${home}_logo.png`, import.meta.url).href}
                  height={20}
                  fit="cover"
                  alt={`${home} logo`}
                />
              </Box>
            </Group>
          );
        },
        sortingFn: (rowA, rowB) => {
          const a = rowA.original.title;
          const b = rowB.original.title;
          return a.localeCompare(b);
        },
        filterFn: 'gameFilter',
        header: 'Game',
      },
      {
        id: 'eastern_time',
        accessorFn: (row) => dayjs(row.eastern_time, 'HH:mm').format('h:mm A'),
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" component="span">
            {renderedCellValue}
          </Text>
        ),
        header: 'Time (ET)',
        filterFn: 'contains',
        enableSorting: false,
      },
      {
        id: 'network',
        header: 'Network',
        accessorFn: (row) =>
          row.network?.replace('ESPN_PLUS', 'ESPN+').replace('ESPN_TWO', 'ESPN2') ?? '',
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" component="span">
            {renderedCellValue}
          </Text>
        ),
        filterFn: 'contains',
        enableSorting: false,
      },
      {
        id: 'viewership',
        accessorFn: (row) => {
          const val = row.viewership || 0;
          if (val >= 1000) {
            return `${Number((val / 1000).toFixed(0)).toLocaleString()}`;
          }
          return val || '';
        },
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" component="span">
            {renderedCellValue}
          </Text>
        ),
        filterFn: 'inNumberRange',
        sortingFn: (rowA, rowB) =>
          (rowA.original.viewership || 0) - (rowB.original.viewership || 0),
        header: 'Viewership (000)',
        minSize: 200,
      },
    ],
    []
  );
  constraintsTable = useMantineReactTable({
    data: events,
    columns,
    layoutMode: 'grid-no-grow',
    enableDensityToggle: false,
    enableColumnFilterModes: false,
    enableGrouping: true,
    enableColumnDragging: false,
    mantineExpandButtonProps: {
      size: 'xs',
    },
    mantineExpandAllButtonProps: {
      size: 'xs',
    },
    enableHiding: false,
    positionToolbarAlertBanner: 'none',
    initialState: {
      // @ts-ignore
      density: '6px',
      grouping: ['date'],
      expanded: true,
      sorting: [{ id: 'date', desc: false }],
    },
    mantineTableContainerProps: { style: { maxHeight: 'calc(100% - 80px)' } },
    paginationDisplayMode: 'pages',
    mantineTableProps: {
      striped: true,
    },
    renderTopToolbarCustomActions: () => (
      <Flex w="100%" my="auto" justify="space-between" align="center">
        <Text fs="italic" mr="auto">
          * Local market blackout
        </Text>
        <Group>
          <Text fw={500}>Date</Text>
          {selectedDate ? (
            <DatePickerInput
              w="150px"
              size="xs"
              radius="xs"
              valueFormat="ddd, MMM DD"
              value={dayjs(selectedDate).toDate()}
              onChange={(value) => setSelectedDate(dayjs.utc(value).format('YYYY-MM-DD'))}
              minDate={dayjs('2024-10-01').toDate()}
              maxDate={dayjs('2025-04-30').toDate()}
              getDayProps={(date) => ({
                disabled: !eventDates.includes(dayjs(date).format('YYYY-MM-DD')),
              })}
            />
          ) : (
            <Input w="150px" />
          )}
          <Divider orientation="vertical" />
          <Button
            onClick={() => downloadCSV(events)}
            variant="default"
            size="xs"
            radius="xs"
            leftSection={<FontAwesomeIcon icon={faCircleDown} color="gray" />}
          >
            Download CSV
          </Button>
        </Group>
      </Flex>
    ),
    enableStickyHeader: true,
    enableGlobalFilter: false,
    enableColumnFilters: true,
    enableColumnActions: false,
    filterFns: {
      gameFilter: (row, columnId, searchTerm) => {
        if (columnId !== 'game') {
          return true;
        }
        const { displayValue } = parseMatchup(row.original);
        return displayValue.toLowerCase().includes(searchTerm.toLowerCase());
      },
    },
    enablePagination: false,
    enableRowVirtualization: true,
    rowVirtualizerInstanceRef,
    renderBottomToolbar: ({ table }) => (
      <Flex
        justify="flex-end"
        bg="#F8F9FA"
        pr="lg"
        h="24px"
        style={{
          borderTop: '1px solid var(--mantine-color-gray-3)',
          fontSize: '14px',
        }}
        align="center"
      >
        {table.getRowModel().rows.length} results
      </Flex>
    ),
  });

  return (
    <Stack gap="xs" align="stretch" h="100%">
      <MantineReactTable table={constraintsTable} />
    </Stack>
  );
}
